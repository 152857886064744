@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  scroll-behavior: smooth;
}

#summary {
  background-color: #f6f6f6;
}

/* src/YouTubePlayer.css */
.bg-youtube-banner {
  background-image: url('/src/assets/moloyalpix.png');
  background-position: center;
  background-repeat: no-repeat;
}

.input-field-solid {
  border: 1px solid #000; /* Add the desired border styles */
  padding: 0.5rem;
  width: 100%;
}

.cart-container {
  padding: 2rem 4rem;
}

.cart-container .h2 {
  font-weight: 400;
  font-size: 30px;
  text-align: center;
}

.cart-container .titles {
  margin: 2rem 0 1rem 0;
}

.cart-container .titles h3 {
  font: 14px;
  font-weight: 400;
  text-transform: uppercase;
}

.cart-item,
.cart-container .titles {
  display: grid;
  align-items: center;
  grid-template-columns: 3fr 1fr 1fr 1fr;
  column-gap: 0.5rem;
}

.cart-item {
  border-top: 1px solid rgb(187, 187, 187);
  padding: 1rem 0;
}

.cart-container .titles .event-title {
  padding-left: 0.5rem;
}
.cart-container .titles .total {
  padding-right: 0.5rem;
  justify-self: right;
  justify-self: right;
}

.cart-item .cart-event {
  display: flex;
}

.cart-item .cart-event img {
  width: 100px;
  max-width: 100%;
  margin-right: 1rem;
}

.cart-item .cart-event h3 {
  font-weight: 400;
}

.cart-item .cart-event button {
  border: none;
  outline: none;
  margin-top: 0.7rem;
  cursor: pointer;
  background: none;
  color: #c10006;
}

.cart-product-quantity {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 130px;
  max-width: 100%;
  border: 0.5px solid rgb(177, 177, 177);
  border-radius: 5px;
}

.cart-product-quantity button {
  border: none;
  outline: none;
  background: none;
  padding: 0.7rem 1.5rem;
  cursor: pointer;
}

.cart-product-quantity .count {
  padding: 0.7rem 0;
}

.cart-product-total-price {
  justify-self: right;
  padding-right: 0.5rem;
  font-weight: 700;
}

.cart-summary {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-top: 1px solid rgb(187, 187, 187);
  padding-top: 2rem;
}

.cart-summary .clear-cart {
  width: 130px;
  max-width: 100%;
  height: 40px;
  border-radius: 5px;
  font-weight: 400;
  letter-spacing: 1.15px;
  border: 0.5px solid rgb(177, 177, 177);
  color: gray;
  background: none;
  outline: none;
  cursor: pointer;
}

.cart-checkout {
  width: 270px;
  max-width: 100%;
}
.cart-checkout .subtotal {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
}

.cart-checkout .subtotal .amount {
  font-weight: 700;
}
.cart-checkout p {
  font-size: 14px;
  font-weight: 200;
  margin: 0.5rem 0;
}
.cart-checkout button {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  font-weight: 400;
  border: none;
  outline: none;
  cursor: pointer;
  background: #25aae1;
  color: #f6f6f6;
  letter-spacing: 1.15px;
}

.continue-shopping,
.start-shopping {
  margin-top: 1rem;
}
.continue-shopping a,
.start-shopping a {
  color: gray;
  text-decoration: none;
  display: flex;
  align-items: center;
}
.continue-shopping a span,
.start-shopping a span {
  margin-left: 0.5rem;
}

.cart-empty {
  font-size: 20px;
  margin-top: 2rem;
  color: rgb(84, 84, 84);
  display: flex;
  flex-direction: column;
  align-items: center;
}
